import React, { Fragment } from 'react'

import { GapProps } from '@/common/components/Gap'
import Link from '@/common/components/Link'
import ArrowRightIcon from '@/common/components/icons/ArrowRightIcon'
import useI18n, { Translation } from '@/common/hooks/useI18n'

import { Wrapper } from './styled'

export interface BreadcrumbProps extends Partial<GapProps> {
  items: { label: Translation; href?: string }[]
}

const Breadcrumb = ({ items, space = 8, ...props }: BreadcrumbProps) => {
  const { t } = useI18n()

  return (
    <Wrapper space={space} {...props}>
      {items.map(({ label, href }, index) => (
        <Fragment key={`breadcrumb-item-${index}`}>
          {index > 0 && <ArrowRightIcon className='text-gray' size={8} />}
          {href ? (
            <Link className='text-primary text-12 text-medium' to={href}>
              {t(label)}
            </Link>
          ) : (
            <div className='text-gray text-12 text-medium'>{t(label)}</div>
          )}
        </Fragment>
      ))}
    </Wrapper>
  )
}

export default Breadcrumb
