import React, {
  useRef,
  useCallback,
  useEffect,
  InputHTMLAttributes,
  ChangeEventHandler,
  KeyboardEventHandler,
} from 'react'

import FieldWrapper, {
  FieldWrapperProps,
} from '@/common/components/FieldWrapper'
import { useForm } from '@/common/components/Form'
import { Input } from '@/common/components/TextField/styled'
import useI18n, { Translation } from '@/common/hooks/useI18n'

import { DEFAULT_PLACEHOLDER } from './translation'

type GooglePlaceFieldProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'value' | 'placeholder' | 'onChange'
> &
  FieldWrapperProps & {
    value?: google.maps.places.PlaceResult
    placeholder?: Translation
    onChange?: (place: google.maps.places.PlaceResult) => void
  }

const GooglePlaceField = ({
  className,
  name,
  value: _value,
  placeholder = DEFAULT_PLACEHOLDER,
  onChange,
  ...props
}: GooglePlaceFieldProps) => {
  const ref = useRef<any>(null)
  const { t } = useI18n()
  const { formState, register, setValue, watch, trigger } = useForm()

  const value = name
    ? (watch?.(name) as google.maps.places.PlaceResult)
    : _value

  const validate = useCallback(() => {
    if (!name || !formState?.isSubmitted) return
    trigger?.(name)
  }, [name, formState?.isSubmitted, trigger])

  const clearValue = useCallback(
    (shouldValidate = true) => {
      if (!name) return
      setValue?.(name, undefined)
      shouldValidate && validate()
    },
    [name, setValue, validate]
  )

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.target.value) return
      clearValue()
    },
    [clearValue]
  )

  const onClick = useCallback(() => {
    if (!ref.current) return
    ref.current.select()
  }, [ref])

  const onKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.key !== 'Enter') return
      event.preventDefault()
    },
    []
  )

  useEffect(() => {
    if (value) return
    clearValue(false)
  }, [value, clearValue])

  useEffect(() => {
    const autocomplete = new google.maps.places.Autocomplete(ref.current, {
      componentRestrictions: {
        country: 'th',
      },
    })

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      onChange?.(place)
      name && setValue?.(name, place)
      validate()
    })
  }, [ref, name, onChange, setValue, validate])

  return (
    <FieldWrapper
      {...props}
      className={className}
      name={name}
      value={value}
      renderValue={value => value?.formatted_address}
    >
      {({ isError }) => (
        <>
          <Input
            {...props}
            ref={ref}
            $isError={isError}
            defaultValue={value?.formatted_address}
            placeholder={t(placeholder)}
            onChange={onInputChange}
            onClick={onClick}
            onKeyPress={onKeyPress}
          />
          {name && <input type='hidden' {...(name && register?.(name))} />}
        </>
      )}
    </FieldWrapper>
  )
}

export default GooglePlaceField
