import React from 'react'

import { IconProps } from './types'

const CheckIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
      height={size}
      width={size}
      {...props}
    >
      <path
        fill='currentColor'
        d='M8.384 15.956a.716.716 0 0 1-.546-.266L4.2 11.4a.9.9 0 0 1 .034-1.175.7.7 0 0 1 1.059.037l3.082 3.645 6.291-7.639a.71.71 0 0 1 .726-.3.782.782 0 0 1 .573.576.894.894 0 0 1-.193.831l-6.838 8.3a.718.718 0 0 1-.546.274z'
        transform='translate(-78 -1319) translate(79 1321) translate(-3.999 -5.956)'
      />
    </svg>
  )
}

export default CheckIcon
