import moment from 'moment'
import qs from 'qs'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import CheckBoxField from '@/common/components/CheckBoxField'
import DateTimeField from '@/common/components/DateTimeField'
import Form, { useForm } from '@/common/components/Form'
import Gap from '@/common/components/Gap'
import GooglePlacesField from '@/common/components/GooglePlacesField'
import Helper from '@/common/components/Helper'
import RangeField from '@/common/components/RangeField'
import SelectField from '@/common/components/SelectField'
import { BEDROOM_OPTIONS, OCCUPANT_OPTIONS } from '@/common/enums'
import useI18n from '@/common/hooks/useI18n'
import useCurrentUser from '@/modules/authentication/hooks/useCurrentUser'
import {
  OBJECTIVE_TITLE,
  OBJECTIVE_PLACEHOLDER,
  WORKPLACE_TITLE,
  WORKPLACE_HELPER,
  BUDGET_TITLE,
  BUDGET_HELPER,
  BEDROOM_TITLE,
  BEDROOM_PLACEHOLDER,
  COMMUTE_BY_TITLE,
  COMMUTE_BY_PLACEHOLDER,
} from '@/modules/property/components/BasicSearchPlaceForm/translation'
import useSearchParamOptions from '@/modules/property/hooks/useSearchParamOptions'
import { SEARCH_PLACE_PATH } from '@/modules/property/paths'
import { SEARCH, BASIC_SEARCH } from '@/modules/property/translation'

import { SubmitButton } from './styled'
import {
  PREFER_AREA_TITLE,
  PREFER_AREA_HELPER,
  TRAVEL_BY_TITLE,
  TRAVEL_BY_PLACEHOLDER,
  ARRIVE_TIME_TITLE,
  LEAVE_TIME_TITLE,
  OCCUPANT_TITLE,
  OCCUPANT_PLACEHOLDER,
  PRIORITY_TITLE,
  PRIORITY_PLACEHOLDER,
  CONFIRM_LABEL,
  INSUFFICIENT_CREDITS_MESSAGE,
} from './translation'

const BUDGET_MIN = 0
const BUDGET_MAX = 100000
const BUDGET_STEP = 1000

const schema = yup.object().shape({
  objective: yup.string().required(),
  workplace: yup.mixed().required(),
  preferArea: yup.mixed().when('enablePreferArea', {
    is: true,
    then: yup.mixed().required(),
  }),
  budget: yup.array().of(yup.number()).required(),
  commuteBy: yup.string().required(),
  travelBy: yup.string().required(),
  arriveTime: yup.mixed().required(),
  leaveTime: yup.mixed().required(),
  bedroom: yup.number().required(),
  occupant: yup.number().required(),
  priority1: yup.number().required(),
  priority2: yup.number().required(),
  priority3: yup.number().required(),
})

export interface AdvancedSearchPlaceFormProps {
  defaultValues?: {
    objective?: string | null
    workplace?: RentAPerfect.GooglePlace | null
    preferArea?: RentAPerfect.GooglePlace | null
    budget?: [number | null, number | null]
    commuteBy?: string | null
    travelBy?: string | null
    arriveTime?: string | null
    leaveTime?: string | null
    bedroom?: number | null
    occupant?: number | null
    firstPriority?: number | null
    secondPriority?: number | null
    thirdPriority?: number | null
  }
  onSubmit?: (values: any) => void
  onClickBasicSearch?: () => void
}

const PriorityField = ({ n }: { n: number }) => {
  const { t } = useI18n()
  const { watch } = useForm()
  const {
    data: { priorities: priorityOptions },
  } = useSearchParamOptions()

  const usedPriorities =
    watch?.(
      ['priority1', 'priority2', 'priority3'].filter(
        name => name !== `priority${n}`
      )
    ) ?? []
  const options = priorityOptions.filter(
    option => option.value === 0 || !usedPriorities.includes(option.value)
  )

  return (
    <SelectField
      name={`priority${n}`}
      label={t(PRIORITY_TITLE, { n })}
      placeholder={t(PRIORITY_PLACEHOLDER, { n })}
      options={options}
    />
  )
}

const AdvancedSearchPlaceForm = ({
  defaultValues,
  onSubmit: _onSubmit,
  onClickBasicSearch,
}: AdvancedSearchPlaceFormProps) => {
  const { t } = useI18n()
  const navigate = useNavigate()
  const { data: currentUser } = useCurrentUser()
  const {
    data: { objectives: objectiveOptions, commutes: commuteOptions },
  } = useSearchParamOptions()

  const isInsufficientCredits = !!currentUser && !currentUser.isAdvanceSearch

  const onSubmit = values => {
    navigate({
      pathname: SEARCH_PLACE_PATH,
      search: `?${qs.stringify({
        mode: 'advance',
        objective: values.objective,
        google_place_id: values.workplace.place_id,
        prefer_google_place_id: values.enablePreferArea
          ? values.preferArea.place_id
          : undefined,
        budget_min: values.budget[0],
        budget_max: values.budget[1],
        commute_by: values.commuteBy,
        travel_by: values.travelBy,
        arrive_time: values.arriveTime.format('HH:mm'),
        leave_time: values.leaveTime.format('HH:mm'),
        bedroom: values.bedroom,
        occupant: values.occupant,
        first_priority: values.priority1,
        second_priority: values.priority2,
        third_priority: values.priority3,
      })}`,
    })
    _onSubmit?.(values)
  }

  return (
    <Form
      defaultValues={{
        ...defaultValues,
        ...(defaultValues?.arriveTime && {
          arriveTime: moment(defaultValues.arriveTime, 'HH:mm'),
        }),
        ...(defaultValues?.leaveTime && {
          leaveTime: moment(defaultValues.leaveTime, 'HH:mm'),
        }),
        workplace: defaultValues?.workplace
          ? {
              place_id: defaultValues.workplace.placeId,
              formatted_address: [
                defaultValues.workplace.name,
                defaultValues.workplace.address,
              ]
                .filter(Boolean)
                .join(', '),
            }
          : undefined,
        preferArea: defaultValues?.preferArea
          ? {
              place_id: defaultValues.preferArea.placeId,
              formatted_address: [
                defaultValues.preferArea.name,
                defaultValues.preferArea.address,
              ]
                .filter(Boolean)
                .join(', '),
            }
          : undefined,
        enablePreferArea: !!defaultValues?.preferArea,
        priority1: defaultValues?.firstPriority,
        priority2: defaultValues?.secondPriority,
        priority3: defaultValues?.thirdPriority,
      }}
      schema={schema}
      onSubmit={onSubmit}
    >
      {({ watch }) => {
        const isConfirmed = watch('confirm')
        const isEnablePreferArea = watch('enablePreferArea')

        return (
          <>
            <Gap space={24} mobileSpace={16} mobileVertical>
              <Gap className='flex-1' space={16} vertical>
                <SelectField
                  name='objective'
                  label={OBJECTIVE_TITLE}
                  placeholder={OBJECTIVE_PLACEHOLDER}
                  options={objectiveOptions}
                />
                <GooglePlacesField
                  name='workplace'
                  label={WORKPLACE_TITLE}
                  helper={WORKPLACE_HELPER}
                />
                <div>
                  <div className='flex align-center mb-8'>
                    <div className='text-14 text-semibold'>
                      {t(PREFER_AREA_TITLE)}
                    </div>
                    <Helper className='ml-4' message={PREFER_AREA_HELPER} />
                  </div>
                  <Gap className='align-start' space={12}>
                    <CheckBoxField className='mt-10' name='enablePreferArea' />
                    <GooglePlacesField
                      className='flex-1'
                      name='preferArea'
                      disabled={!isEnablePreferArea}
                      error={isEnablePreferArea ? undefined : ''}
                    />
                  </Gap>
                </div>
                <RangeField
                  name='budget'
                  label={BUDGET_TITLE}
                  helper={BUDGET_HELPER}
                  min={BUDGET_MIN}
                  max={BUDGET_MAX}
                  step={BUDGET_STEP}
                />
                <SelectField
                  name='commuteBy'
                  label={COMMUTE_BY_TITLE}
                  placeholder={COMMUTE_BY_PLACEHOLDER}
                  options={commuteOptions}
                />
                <SelectField
                  name='travelBy'
                  label={TRAVEL_BY_TITLE}
                  placeholder={TRAVEL_BY_PLACEHOLDER}
                  options={commuteOptions}
                />
              </Gap>
              <Gap className='flex-1' space={16} vertical>
                <DateTimeField
                  type='time'
                  name='arriveTime'
                  label={ARRIVE_TIME_TITLE}
                />
                <DateTimeField
                  type='time'
                  name='leaveTime'
                  label={LEAVE_TIME_TITLE}
                />
                <SelectField
                  name='bedroom'
                  label={BEDROOM_TITLE}
                  placeholder={BEDROOM_PLACEHOLDER}
                  options={BEDROOM_OPTIONS}
                />
                <SelectField
                  name='occupant'
                  label={OCCUPANT_TITLE}
                  placeholder={OCCUPANT_PLACEHOLDER}
                  options={OCCUPANT_OPTIONS}
                />
                <PriorityField n={1} />
                <PriorityField n={2} />
                <PriorityField n={3} />
              </Gap>
            </Gap>
            <Gap className='mt-24 align-center mx-auto' space={16} vertical>
              {isInsufficientCredits ? (
                <div className='text-12 text-error text-center'>
                  {t(INSUFFICIENT_CREDITS_MESSAGE)}
                </div>
              ) : null}
              <CheckBoxField
                name='confirm'
                label={CONFIRM_LABEL}
                disabled={isInsufficientCredits}
              />
              <SubmitButton
                type='submit'
                disabled={!isConfirmed || isInsufficientCredits}
              >
                {t(SEARCH)}
              </SubmitButton>
              {onClickBasicSearch ? (
                <div
                  className='text-primary text-12 text-semibold text-center block clickable'
                  onClick={onClickBasicSearch}
                >
                  {t(BASIC_SEARCH)}
                </div>
              ) : null}
            </Gap>
          </>
        )
      }}
    </Form>
  )
}

export default AdvancedSearchPlaceForm
