import { colord } from 'colord'
import { Range as RcRange } from 'rc-slider'
import styled from 'styled-components'

const HANDLE_SIZE = 12

export const StyledRange = styled(RcRange)`
  width: calc(100% - ${HANDLE_SIZE}px) !important;
  height: auto !important;
  padding: 0 !important;
  margin-left: ${HANDLE_SIZE / 2}px;

  .rc-slider-rail {
    width: calc(100% + ${HANDLE_SIZE}px) !important;
    margin-left: -${HANDLE_SIZE / 2}px;
  }

  .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .rc-slider-handle {
    border-color: ${({ theme }) => theme.colors.primary} !important;
    width: ${HANDLE_SIZE}px;
    height: ${HANDLE_SIZE}px;
    margin-top: -${HANDLE_SIZE / 3}px;

    &:hover {
      border-color: ${({ theme }) =>
        colord(theme.colors.primary).darken(0.1).toHex()} !important;
    }
  }

  .rc-slider-handle-dragging {
    border-color: ${({ theme }) =>
      colord(theme.colors.primary).darken(0.1).toHex()} !important;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.primary} !important;
  }
`
