import { useQuery } from 'react-query'

import useApis from '@/common/hooks/useApis'
import { PrefetchRequest } from '@/common/types'

const QUERY_KEY = 'search-param-options'

const useSearchParamOptions = () => {
  const { apis } = useApis()

  const { data = { objectives: [], commutes: [], priorities: [] }, ...query } =
    useQuery(QUERY_KEY, () => apis.search.getSearchParamOptions())

  return { data, ...query }
}

export const prefetchPriorityOptions = async ({
  apis,
  queryClient,
}: PrefetchRequest) => {
  return queryClient.fetchQuery(QUERY_KEY, () =>
    apis.search.getSearchParamOptions()
  )
}

export default useSearchParamOptions
