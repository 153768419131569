import React from 'react'

import { IconProps } from './types'

const ClockIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
      height={size}
      width={size}
      {...props}
    >
      <g>
        <g>
          <path d='M7 0a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7zm0 12.978A5.978 5.978 0 1 1 12.978 7 5.985 5.985 0 0 1 7 12.978z' />
        </g>
      </g>
      <g>
        <g>
          <path
            d='M228.182 128.872h-2.275v-3.15a.525.525 0 1 0-1.05 0v3.678a.525.525 0 0 0 .525.525h2.8a.525.525 0 1 0 0-1.05z'
            transform='translate(6.087 3.377) translate(-224.857 -125.197)'
          />
        </g>
      </g>
    </svg>
  )
}

export default ClockIcon
