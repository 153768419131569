import { useQuery, UseQueryOptions } from 'react-query'

import useApis from '@/common/hooks/useApis'
import usePermission from '@/common/hooks/usePermission'

const QUERY_KEY = 'owner-room'

interface Props {
  roomId?: string
  queryOptions?: Omit<
    UseQueryOptions<unknown, unknown, RentAPerfect.OwnerRoom>,
    'queryKey' | 'queryFn'
  >
}

const useOwnerRoom = ({ queryOptions, roomId }: Props) => {
  const { apis } = useApis()
  const { isOwner } = usePermission()

  const { data = null, ...query } = useQuery(
    [QUERY_KEY, { roomId }],
    () => apis.owner.getRoom({ roomId: roomId! }),
    {
      ...queryOptions,
      enabled: isOwner && !!roomId && (queryOptions?.enabled ?? true),
    }
  )

  return { ...query, data }
}

export default useOwnerRoom
