import Dialog from 'rc-dialog'
import styled, { css } from 'styled-components'

import { media } from '@/common/utils/viewport'

interface StyledDialogProps {
  $width: number
  $mobileFullScreen: boolean
}

export const StyledDialog = styled(Dialog)<StyledDialogProps>`
  margin: 100px 24px !important;

  .rc-dialog-content {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  .rc-dialog-body {
    padding: 24px;
  }

  .rc-dialog-close {
    font-size: 12px !important;
    padding: 0 !important;
    top: 16px !important;
    right: 16px !important;
    opacity: 1 !important;
  }

  ${media.up('sm')`
    width: ${({ $width }) => $width}px !important;
    max-width: 90%;
    margin: 100px auto !important;
  `}

  ${({ $mobileFullScreen }) =>
    $mobileFullScreen &&
    css`
      ${media.down('sm')`
        margin: 0 !important;

        .rc-dialog-content {
          border-radius: 0;
        }
      `}
    `}
`
