import { DialogProps } from 'rc-dialog'
import React from 'react'

import 'rc-dialog/assets/index.css'

import CloseIcon from '@/common/components/icons/CloseIcon'

import { StyledDialog } from './styled'

export interface ModalProps extends Omit<DialogProps, 'visible' | 'closeIcon'> {
  isOpen?: boolean
  width?: number
  mobileFullScreen?: boolean
}

const Modal = ({
  isOpen,
  width = 600,
  mobileFullScreen = false,
  ...props
}: ModalProps) => {
  return (
    <StyledDialog
      $width={width}
      $mobileFullScreen={mobileFullScreen}
      visible={isOpen}
      animation='zoom'
      closeIcon={<CloseIcon size={12} />}
      {...props}
    />
  )
}

export default Modal
