export const OBJECTIVE_TITLE = { en: 'Objective', th: 'วัตถุประสงค์การค้นหา' }
export const OBJECTIVE_PLACEHOLDER = {
  en: 'Select your objective',
  th: 'กรุณาเลือกวัตถุประสงค์การค้นหา',
}

export const WORKPLACE_TITLE = {
  en: 'Workplace',
  th: 'สถานที่ทำงาน',
}
export const WORKPLACE_HELPER = {
  en: 'Enter the name of the location where you want to live close to (e.g., Empire Tower, Chulalongkorn University, Central world)',
  th: 'กรุณากรอกชื่อสถานที่ที่คุณต้องการอยู่ใกล้เคียง (เช่น เอ็มไพร์ ทาวเวอร์, มหาวิทยาลัยจุฬาลงกรณ์, เซ็นทรัลเวิลด์)',
}

export const BUDGET_TITLE = {
  en: 'Rental Budget (฿/Month)',
  th: 'งบประมาณการเช่า (บาท/เดือน)',
}
export const BUDGET_HELPER = {
  en: 'Please leave default in case there is no rental budget expectation',
  th: 'โปรดปล่อยให้เป็นค่าเริ่มต้นในกรณีที่ไม่มีการคาดหวังงบประมาณการเช่า',
}

export const BEDROOM_TITLE = {
  en: 'Number of Bedrooms',
  th: 'จำนวนห้องนอนที่ต้องการ',
}
export const BEDROOM_PLACEHOLDER = {
  en: 'Select number of bedrooms',
  th: 'กรุณาเลือกจำนวนห้องนอน',
}

export const COMMUTE_BY_TITLE = {
  en: 'I mostly commute by',
  th: 'วิธีที่ใช้ในการเดินทางโดยประจำ',
}
export const COMMUTE_BY_PLACEHOLDER = {
  en: 'Select transportation',
  th: 'กรุณาเลือกวิธีการเดินทาง',
}
