import moment from 'moment'
import React, { useContext } from 'react'
import toast from 'react-hot-toast'

import useApis from '@/common/hooks/useApis'
import useI18n from '@/common/hooks/useI18n'
import useLoadingOverlay from '@/common/hooks/useLoadingOverlay'
import { SAVE_SUCCESS_MESSAGE } from '@/common/translation'
import RentalDetailsFormModal from '@/modules/me/pages/OwnerListingsPage/components/RentalDetailsFormModal'
import { OwnerListingsActionContext } from '@/modules/me/pages/OwnerListingsPage/context'
import useOwnerRoom from '@/modules/owner/hooks/useOwnerRoom'
import useOwnerRooms from '@/modules/owner/hooks/useOwnerRooms'

const MakeAsRentedModal = () => {
  const { t } = useI18n()
  const { apis } = useApis()
  const loadingOverlay = useLoadingOverlay()
  const { currentAction, setAction } = useContext(OwnerListingsActionContext)

  const { name: actionName, room } = currentAction ?? {}

  const { refetchAll: refetchAllOwnerRooms } = useOwnerRooms()
  const { refetch: refetchOwnerRoom } = useOwnerRoom({
    roomId: room?.id,
    queryOptions: { enabled: false },
  })

  const onSubmit = async values => {
    loadingOverlay.open()

    apis.owner
      .createUserContract({
        ...values,
        roomId: room!.id,
        dateStart: moment(values.dateStart).format('YYYY-MM-DD'),
        dateExpired: moment(values.dateExpired).format('YYYY-MM-DD'),
      })
      .then(async () => {
        await refetchAllOwnerRooms()
        refetchOwnerRoom()
        toast.success(t(SAVE_SUCCESS_MESSAGE))
        setAction(null)
      })
      .finally(() => {
        loadingOverlay.close()
      })
  }

  return (
    <RentalDetailsFormModal
      room={room}
      defaultValues={{
        price: room?.RoomContracts ? room.RoomContracts.price : undefined,
      }}
      isOpen={actionName === 'make_as_rented'}
      onClose={() => setAction(null)}
      onSubmit={onSubmit}
    />
  )
}

export default MakeAsRentedModal
