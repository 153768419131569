import React from 'react'

import { IconProps } from './types'

const CalendarIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 13.999 13.999'
      height={size}
      width={size}
      {...props}
    >
      <g>
        <g>
          <g>
            <g>
              <path
                d='M12.358 1.094H11.7V0h-1.092v1.094H3.39V0H2.3v1.094h-.66A1.642 1.642 0 0 0 0 2.734v9.624A1.642 1.642 0 0 0 1.64 14h10.718A1.642 1.642 0 0 0 14 12.358V2.734a1.642 1.642 0 0 0-1.642-1.64zm.542 11.264a.547.547 0 0 1-.547.547H1.64a.547.547 0 0 1-.547-.547V5.14H12.9zm0-8.312H1.094V2.734a.547.547 0 0 1 .547-.547H2.3v1.094h1.09V2.187h7.218v1.094H11.7V2.187h.656a.547.547 0 0 1 .547.547z'
                transform='translate(-6846 -2095) translate(6846 2095)'
              />
            </g>
          </g>
          <g>
            <g transform='translate(-6846 -2095) translate(6846 2095) translate(8.94 5.96)'>
              <rect height='1' transform='translate(.06 .04)' />
            </g>
          </g>
          <g>
            <g transform='translate(-6846 -2095) translate(6846 2095) translate(10.928 5.96)'>
              <rect height='1' transform='translate(.072 .04)' />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(1.987 5.96) translate(.013 .04)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(3.974 5.96) translate(.026 .04)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(5.96 5.96) translate(.04 .04)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(7.96 5.96) translate(.04 .04)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(9.96 5.96) translate(.04 .04)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(1.987 7.947) translate(.013 .053)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(1.987 9.947) translate(.013 .053)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(3.974 7.947) translate(.026 .053)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(3.974 9.947) translate(.026 .053)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(5.96 7.947) translate(.04 .053)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(5.96 9.947) translate(.04 .053)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(7.96 9.947) translate(.04 .053)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(7.96 7.947) translate(.04 .053)'
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d='M0 0H1V1H0z'
                  transform='translate(-6846 -2095) translate(6846 2095) translate(.5 .5) translate(9.96 7.947) translate(.04 .053)'
                />
              </g>
            </g>
          </g>
          <g>
            <g transform='translate(-6846 -2095) translate(6846 2095) translate(8.94 7.947)'>
              <rect height='1' transform='translate(.06 .053)' />
            </g>
          </g>
          <g>
            <g transform='translate(-6846 -2095) translate(6846 2095) translate(1.987 10.927)'>
              <rect width='1' transform='translate(.013 .073)' />
            </g>
          </g>
          <g>
            <g transform='translate(-6846 -2095) translate(6846 2095) translate(3.974 10.927)'>
              <rect width='1' transform='translate(.026 .073)' />
            </g>
          </g>
          <g>
            <g transform='translate(-6846 -2095) translate(6846 2095) translate(8.94 10.927)'>
              <rect transform='translate(.06 .073)' />
            </g>
          </g>
          <g>
            <g transform='translate(-6846 -2095) translate(6846 2095) translate(10.928 7.947)'>
              <rect height='1' transform='translate(.072 .053)' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CalendarIcon
