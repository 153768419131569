import cn from 'classnames'
import React, { useState, useCallback, useEffect } from 'react'

import FieldWrapper from '@/common/components/FieldWrapper'
import { useForm } from '@/common/components/Form'
import Gap from '@/common/components/Gap'
import NumberField, { NumberFieldProps } from '@/common/components/NumberField'
import Range from '@/common/components/Range'
import { Translation } from '@/common/hooks/useI18n'

import { MIN_TITLE, MAX_TITLE } from './translation'

export interface RangeFieldProps
  extends Omit<
    NumberFieldProps,
    'value' | 'min' | 'max' | 'step' | 'onChange'
  > {
  value?: [number, number]
  minLabel?: Translation
  maxLabel?: Translation
  min?: number
  max?: number
  step?: number
  hideRangeBar?: boolean
  onChange?: (value: [number, number]) => void
}

const RangeField = ({
  className,
  name,
  helper,
  value: _value,
  minLabel = MIN_TITLE,
  maxLabel = MAX_TITLE,
  min = 0,
  max = 100,
  step = 1,
  hideRangeBar = false,
  onChange,
  ...props
}: RangeFieldProps) => {
  const [mounted, setMounted] = useState(false)
  const { setValue, watch } = useForm()

  const value = name ? (watch?.(name) as [number, number]) : _value
  const inputProps = {
    ...props,
    min,
    max,
    step,
  }

  const onChangeValue = useCallback(
    (value: [number, number]) => {
      if (name) setValue?.(name, value)
      onChange?.(value)
    },
    [name, onChange, setValue]
  )

  const renderValue = value => {
    if (typeof value?.[0] !== 'number') return undefined
    if (typeof value?.[1] !== 'number') return undefined
    return `${value[0] - value[1]}`
  }

  useEffect(() => {
    if (typeof value?.[0] !== 'number' && typeof value?.[1] !== 'number') {
      onChangeValue([min, max])
    } else if (typeof value?.[0] !== 'number') {
      onChangeValue([min, value[1]])
    } else if (typeof value?.[1] !== 'number') {
      onChangeValue([value[0], max])
    }
    setMounted(true)
  }, [value, min, max, onChangeValue])

  return (
    <FieldWrapper
      {...props}
      className={className}
      name={name}
      value={value}
      helper={helper}
      renderValue={renderValue}
    >
      <Gap className={cn(!hideRangeBar && 'mb-16')} space={12}>
        <div className='flex-1'>
          <NumberField
            {...inputProps}
            label={minLabel}
            value={value?.[0]}
            onChange={(minValue: number) =>
              onChangeValue([
                Math.min(minValue, value?.[1] as number),
                value?.[1] as number,
              ])
            }
          />
        </div>
        <div className='flex-1'>
          <NumberField
            {...inputProps}
            label={maxLabel}
            value={value?.[1]}
            onChange={(maxValue: number) =>
              onChangeValue([
                value?.[0] as number,
                Math.max(maxValue, value?.[0] as number),
              ])
            }
          />
        </div>
      </Gap>
      {!hideRangeBar ? (
        <div className='pb-8'>
          <Range
            value={value ?? [min, max]}
            min={min}
            max={max}
            step={step}
            onChange={(value: [number, number]) => onChangeValue(value)}
          />
        </div>
      ) : null}
    </FieldWrapper>
  )
}

export default RangeField
