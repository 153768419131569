import loadable from '@loadable/component'
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import { InputNumberProps } from 'rc-input-number'
import React from 'react'
import { Controller } from 'react-hook-form'
import 'rc-input-number/assets/index.css'

import FieldWrapper, {
  FieldWrapperProps,
} from '@/common/components/FieldWrapper'
import { useForm } from '@/common/components/Form'
import { Input } from '@/common/components/TextField/styled'
import useI18n, { Translation } from '@/common/hooks/useI18n'
import { numberToString } from '@/common/utils/string'

const StyledInputNumber = loadable(() => import('./styled'), {
  ssr: false,
  resolveComponent: components => components.StyledInputNumber,
})

export type NumberFieldProps = Omit<InputNumberProps, 'placeholder'> &
  FieldWrapperProps & {
    placeholder?: Translation
    decimals?: number | null
  }

const NumberField = ({
  name,
  placeholder = '0',
  decimals = 0,
  onChange,
  onKeyPress,
  ...props
}: NumberFieldProps) => {
  const { t } = useI18n()
  const { control } = useForm()

  const formatter = value =>
    !isNull(decimals) && isNumber(value)
      ? numberToString(value, { decimals })
      : value

  const inputProps = {
    placeholder: t(placeholder),
    fallback: <Input readOnly />,
    formatter,
    onChange,
    ...props,
  }

  if (!name || !control)
    return (
      <FieldWrapper {...props} name={name} renderValue={formatter}>
        {({ isError }) => (
          <StyledInputNumber {...inputProps} $isError={isError} />
        )}
      </FieldWrapper>
    )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FieldWrapper {...props} name={name} renderValue={formatter}>
          {({ isError }) => (
            <StyledInputNumber
              {...field}
              {...inputProps}
              $isError={isError}
              onChange={value => {
                onChange?.(value)
                field.onChange(value)
              }}
            />
          )}
        </FieldWrapper>
      )}
    />
  )
}

export default NumberField
