import styled from 'styled-components'

import Modal from '@/common/components/Modal'
import { media } from '@/common/utils/viewport'

export const StyledModal = styled(Modal)`
  margin: 24px !important;

  ${media.up('sm')`
    margin: 80px auto !important;
  `}
`
