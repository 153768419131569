import { Moment } from 'moment'
import { PickerProps } from 'rc-picker'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'
import { DisabledTime } from 'rc-picker/lib/interface'
import enUS from 'rc-picker/lib/locale/en_US'
import thTH from 'rc-picker/lib/locale/th_TH'
import React, { useRef } from 'react'
import { Controller } from 'react-hook-form'

import 'rc-picker/assets/index.css'

import FieldWrapper, {
  FieldWrapperProps,
} from '@/common/components/FieldWrapper'
import { useForm } from '@/common/components/Form'
import { Input } from '@/common/components/TextField/styled'
import CalendarIcon from '@/common/components/icons/CalendarIcon'
import ClockIcon from '@/common/components/icons/ClockIcon'
import useI18n, { Translation } from '@/common/hooks/useI18n'
import { dateToString } from '@/common/utils/string'

import { StyledPicker } from './styled'
import { DEFAULT_DATE_PLACEHOLDER } from './translation'

const DEFAULT_CONFIG = {
  date: {
    format: 'D MMM YYYY',
    placeholder: DEFAULT_DATE_PLACEHOLDER,
    suffixIcon: <CalendarIcon size={14} />,
  },
  time: {
    format: 'HH:mm',
    placeholder: '00:00',
    minuteStep: 15,
    showSecond: false,
    suffixIcon: <ClockIcon size={14} />,
  },
}

type DateTimeFieldProps = Omit<
  PickerProps<Moment>,
  'picker' | 'locale' | 'generateConfig' | 'dropdownClassName' | 'placeholder'
> &
  FieldWrapperProps & {
    type: 'date' | 'time'
    placeholder?: Translation
    disabledTime?: DisabledTime<Moment>
  }

const DateTimeField = ({
  type,
  name,
  placeholder = DEFAULT_CONFIG[type].placeholder,
  ...props
}: DateTimeFieldProps) => {
  const ref = useRef<any>()
  const { language, t } = useI18n()
  const { control } = useForm()

  const pickerProps = {
    ...DEFAULT_CONFIG[type],
    picker: type,
    locale: language === 'th' ? thTH : enUS,
    generateConfig: momentGenerateConfig,
    placeholder: t(placeholder),
    inputReadOnly: true,
    getPopupContainer: () => ref.current,
    ...props,
  }

  const renderValue = value =>
    dateToString(value, { format: pickerProps.format as string })

  if (!name || !control)
    return (
      <div ref={ref}>
        <FieldWrapper {...props} name={name} renderValue={renderValue}>
          {({ isError }) => (
            <StyledPicker
              {...pickerProps}
              inputRender={props => <Input {...props} $isError={isError} />}
            />
          )}
        </FieldWrapper>
      </div>
    )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div ref={ref} className='full-width'>
          <FieldWrapper {...props} name={name} renderValue={renderValue}>
            {({ isError }) => (
              <StyledPicker
                {...field}
                {...pickerProps}
                inputRender={props => <Input {...props} $isError={isError} />}
                onChange={value => field.onChange(value)}
                onSelect={value => field.onChange(value)}
              />
            )}
          </FieldWrapper>
        </div>
      )}
    />
  )
}

export default DateTimeField
