import styled from 'styled-components'

import { media } from '@/common/utils/viewport'

export const FormItemsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;

  ${media.up('sm')`
    grid-template-columns: repeat(2, 1fr);
  `}
`
