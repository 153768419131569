import React from 'react'

import useI18n from '@/common/hooks/useI18n'
import AdvancedSearchPlaceForm, {
  AdvancedSearchPlaceFormProps,
} from '@/modules/property/components/AdvancedSearchPlaceForm'
import BasicSearchPlaceForm, {
  BasicSearchPlaceFormProps,
} from '@/modules/property/components/BasicSearchPlaceForm'
import { ADVANCED_SEARCH, BASIC_SEARCH } from '@/modules/property/translation'

import { StyledModal } from './styled'

interface SearchPlaceModalProps {
  isOpen?: boolean
  mode?: 'basic' | 'advance'
  defaultValues?:
    | BasicSearchPlaceFormProps['defaultValues']
    | AdvancedSearchPlaceFormProps['defaultValues']
  onClose: () => void
  onChangeMode?: (mode: 'basic' | 'advance') => void
}

const SearchPlaceModal = ({
  isOpen,
  mode,
  defaultValues,
  onClose,
  onChangeMode,
}: SearchPlaceModalProps) => {
  const { t } = useI18n()

  const isAdvancedMode = mode === 'advance'

  return (
    <StyledModal
      isOpen={isOpen && !!mode}
      width={isAdvancedMode ? 954 : 457}
      onClose={onClose}
      destroyOnClose
    >
      <div className='text-20 text-semibold text-center mb-24'>
        {t(isAdvancedMode ? ADVANCED_SEARCH : BASIC_SEARCH)}
      </div>
      {isAdvancedMode ? (
        <AdvancedSearchPlaceForm
          defaultValues={defaultValues}
          onSubmit={onClose}
          onClickBasicSearch={
            onChangeMode ? () => onChangeMode('basic') : undefined
          }
        />
      ) : (
        <BasicSearchPlaceForm
          defaultValues={defaultValues}
          onSubmit={onClose}
          onClickAdvancedSearch={
            onChangeMode ? () => onChangeMode('advance') : undefined
          }
        />
      )}
    </StyledModal>
  )
}

export default SearchPlaceModal
