import cn from 'classnames'
import React, {
  ComponentType,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
} from 'react'

import FieldWrapper, {
  FieldWrapperProps,
} from '@/common/components/FieldWrapper'
import { useForm } from '@/common/components/Form'
import { IconProps } from '@/common/components/icons/types'
import useI18n, { Translation } from '@/common/hooks/useI18n'

import {
  InputWrapper,
  Input,
  LeftIconWrapper,
  RightIconWrapper,
} from './styled'

export type TextFieldProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'placeholder'
> &
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'placeholder'> &
  FieldWrapperProps & {
    placeholder?: Translation
    multiline?: boolean
    leftIcon?: ComponentType<IconProps>
    rightIcon?: ComponentType<IconProps>
    leftIconSize?: number
    rightIconSize?: number
    onClickLeftIcon?: () => void
    onClickRightIcon?: () => void
  }

const TextField = ({
  className,
  name,
  placeholder = '',
  multiline = false,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  leftIconSize = 16,
  rightIconSize = 16,
  onClickLeftIcon,
  onClickRightIcon,
  ...props
}: TextFieldProps) => {
  const { t } = useI18n()
  const { register } = useForm()

  const type = multiline ? 'textarea' : 'input'

  return (
    <FieldWrapper {...props} className={className} name={name}>
      {({ isError }) => (
        <InputWrapper>
          {LeftIcon ? (
            <LeftIconWrapper onClick={onClickLeftIcon}>
              <LeftIcon size={leftIconSize} />
            </LeftIconWrapper>
          ) : null}
          <Input
            {...props}
            {...(name && register?.(name))}
            as={type}
            className={cn(LeftIcon && 'pl-40', RightIcon && 'pr-40')}
            name={name}
            placeholder={t(placeholder)}
            $isError={isError}
            $isMultiline={multiline}
          />
          {RightIcon ? (
            <RightIconWrapper onClick={onClickRightIcon}>
              <RightIcon size={rightIconSize} />
            </RightIconWrapper>
          ) : null}
        </InputWrapper>
      )}
    </FieldWrapper>
  )
}

export default TextField
