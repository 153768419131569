import qs from 'qs'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import Button from '@/common/components/Button'
import Form from '@/common/components/Form'
import Gap from '@/common/components/Gap'
import GooglePlacesField from '@/common/components/GooglePlacesField'
import RangeField from '@/common/components/RangeField'
import SelectField from '@/common/components/SelectField'
import { BEDROOM_OPTIONS } from '@/common/enums'
import useI18n from '@/common/hooks/useI18n'
import useSearchParamOptions from '@/modules/property/hooks/useSearchParamOptions'
import { SEARCH_PLACE_PATH } from '@/modules/property/paths'
import { SEARCH, ADVANCED_SEARCH } from '@/modules/property/translation'

import {
  OBJECTIVE_TITLE,
  OBJECTIVE_PLACEHOLDER,
  WORKPLACE_TITLE,
  WORKPLACE_HELPER,
  BUDGET_TITLE,
  BUDGET_HELPER,
  BEDROOM_TITLE,
  BEDROOM_PLACEHOLDER,
  COMMUTE_BY_TITLE,
  COMMUTE_BY_PLACEHOLDER,
} from './translation'

const BUDGET_MIN = 0
const BUDGET_MAX = 100000
const BUDGET_STEP = 1000

const schema = yup.object().shape({
  objective: yup.string().required(),
  workplace: yup.mixed().required(),
  budget: yup.array().of(yup.number()).required(),
  bedroom: yup.number().required(),
  commuteBy: yup.string().required(),
})

export interface BasicSearchPlaceFormProps {
  defaultValues?: {
    objective?: string | null
    workplace?: RentAPerfect.GooglePlace | null
    budget?: [number | null, number | null]
    bedroom?: number | null
    commuteBy?: string | null
  }
  onSubmit?: (values: any) => void
  onClickAdvancedSearch?: () => void
}

const BasicSearchPlaceForm = ({
  defaultValues,
  onSubmit: _onSubmit,
  onClickAdvancedSearch,
}: BasicSearchPlaceFormProps) => {
  const { t } = useI18n()
  const navigate = useNavigate()
  const {
    data: { objectives: objectiveOptions, commutes: commuteOptions },
  } = useSearchParamOptions()

  const onSubmit = values => {
    navigate({
      pathname: SEARCH_PLACE_PATH,
      search: `?${qs.stringify({
        objective: values.objective,
        google_place_id: values.workplace.place_id,
        budget_min: values.budget[0],
        budget_max: values.budget[1],
        bedroom: values.bedroom,
        commute_by: values.commuteBy,
      })}`,
    })
    _onSubmit?.(values)
  }

  return (
    <Form
      defaultValues={{
        ...defaultValues,
        workplace: defaultValues?.workplace
          ? {
              place_id: defaultValues.workplace.placeId,
              formatted_address: [
                defaultValues.workplace.name,
                defaultValues.workplace.address,
              ]
                .filter(Boolean)
                .join(', '),
            }
          : undefined,
      }}
      schema={schema}
      onSubmit={onSubmit}
    >
      <Gap space={16} vertical>
        <SelectField
          name='objective'
          label={OBJECTIVE_TITLE}
          placeholder={OBJECTIVE_PLACEHOLDER}
          options={objectiveOptions}
        />
        <GooglePlacesField
          name='workplace'
          label={WORKPLACE_TITLE}
          helper={WORKPLACE_HELPER}
        />
        <RangeField
          name='budget'
          label={BUDGET_TITLE}
          helper={BUDGET_HELPER}
          min={BUDGET_MIN}
          max={BUDGET_MAX}
          step={BUDGET_STEP}
        />
        <SelectField
          name='bedroom'
          label={BEDROOM_TITLE}
          placeholder={BEDROOM_PLACEHOLDER}
          options={BEDROOM_OPTIONS}
        />
        <SelectField
          name='commuteBy'
          label={COMMUTE_BY_TITLE}
          placeholder={COMMUTE_BY_PLACEHOLDER}
          options={commuteOptions}
        />
      </Gap>
      <Button className='mt-20' type='submit' variant='gradient' fullWidth>
        {t(SEARCH)}
      </Button>
      {onClickAdvancedSearch ? (
        <div
          className='text-primary text-12 text-semibold text-center block mt-16 clickable'
          onClick={onClickAdvancedSearch}
        >
          {t(ADVANCED_SEARCH)}
        </div>
      ) : null}
    </Form>
  )
}

export default BasicSearchPlaceForm
