export const TITLE = { en: 'Rental details', th: 'รายละเอียดการปล่อยเช่า' }

export const CONTRACT_START_DATE_TITLE = {
  en: 'Contract start date',
  th: 'วันเริ่มสัญญา',
}

export const CONTRACT_END_DATE_TITLE = {
  en: 'Contract end date',
  th: 'วันสิ้นสุดสัญญา',
}

export const PAYMENT_DATE_TITLE = {
  en: 'Payment date of every month',
  th: 'วันที่ชำระค่าเช่า',
}

export const PAYMENT_DATE_MIN_TITLE = { en: 'From', th: 'ระว่างวันที่' }

export const PAYMENT_DATE_MAX_TITLE = { en: 'To', th: 'ถึงวันที่' }

export const PRICE_TITLE = { en: 'Price (฿/month)', th: 'ราคาเช่า (บาท/เดือน)' }

export const RENTER_TITLE = { en: 'Renter name', th: 'ชื่อผู้เช่า' }

export const RENTER_PLACEHOLDER = {
  en: 'Enter renter name',
  th: 'กรุณากรอกชื่อผู้เช่า',
}

export const BROKER_TITLE = { en: 'Broker name', th: 'ชื่อนายหน้า' }

export const BROKER_PLACEHOLDER = {
  en: 'Select broker',
  th: 'กรุณาเลือกนายหน้า',
}

export const SUBMIT = { en: 'Submit', th: 'บันทึก' }
