import RCSwitch from 'rc-switch'
import styled from 'styled-components'

export const StyledSwitch = styled(RCSwitch)`
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  height: 20px !important;
  width: 38px !important;

  &.rc-switch-checked {
    background-color: ${props => props.theme.colors.success} !important;
    border-color: ${props => props.theme.colors.success} !important;
  }

  &:after {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2) !important;
    top: 0px !important;
    left: 0px !important;
    transform: unset !important;
  }

  &.rc-switch-checked:after {
    left: 18px !important;
  }

  &:focus {
    box-shadow: none !important;
  }
`
