import React from 'react'

import { IconProps } from './types'

const ArrowRightIcon = ({ size = 24, ...props }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 12 20'
      height={size}
      width={(size / 20) * 12}
      {...props}
    >
      <path
        fill='currentColor'
        d='M3.387 291.965a1.329 1.329 0 0 0-1.27.927 1.491 1.491 0 0 0 .4 1.593l8.1 7.457-8.1 7.454a1.474 1.474 0 0 0-.523 1.374 1.4 1.4 0 0 0 .9 1.126 1.26 1.26 0 0 0 1.343-.347l9.276-8.526a1.492 1.492 0 0 0 0-2.164l-9.276-8.535a1.275 1.275 0 0 0-.848-.359z'
        transform='translate(-1.976 -291.965)'
      />
    </svg>
  )
}

export default ArrowRightIcon
