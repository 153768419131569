export const PREFER_AREA_TITLE = {
  en: 'Prefer Area',
  th: 'บริเวณที่ต้องการอยู่อาศัย',
}
export const PREFER_AREA_HELPER = {
  en: 'Enter the name of the location where you want to live close to (e.g., Empire Tower, Chulalongkorn University, Central world)',
  th: 'กรุณากรอกชื่อสถานที่ที่คุณต้องการอยู่ใกล้เคียง (เช่น เอ็มไพร์ ทาวเวอร์, มหาวิทยาลัยจุฬาลงกรณ์, เซ็นทรัลเวิลด์)',
}

export const TRAVEL_BY_TITLE = {
  en: 'On holidays or weekends, I usually travel by',
  th: 'ในวันหยุดฉันเดินทางโดย',
}
export const TRAVEL_BY_PLACEHOLDER = {
  en: 'Select transportation',
  th: 'กรุณาเลือกวิธีการเดินทาง',
}

export const ARRIVE_TIME_TITLE = {
  en: 'I usually reach my destination (eg., workplace, university) at',
  th: 'ฉันถึงที่ทำงาน หรือ สถานศึกษาโดยประมาณ',
}

export const LEAVE_TIME_TITLE = {
  en: 'I usually leave from my destination (eg., workplace, university) at',
  th: 'ฉันออกจากที่ทำงาน หรือ สถานศึกษาโดยประมาน (เวลากลับบ้านโดยปรกติ)',
}

export const OCCUPANT_TITLE = {
  en: 'Number of Occupants',
  th: 'จำนวนผู้อยู่อาศัย',
}
export const OCCUPANT_PLACEHOLDER = {
  en: 'Select number of occupants',
  th: 'กรุณาเลือกจำนวนผู้อยู่อาศัย',
}

export const PRIORITY_TITLE = {
  en: '{n, selectordinal, one {#st}  two {#nd} few {#rd} other {#th}} Priority',
  th: 'สิ่งที่ให้ความสำคัญ อันดับที่ {n}',
}
export const PRIORITY_PLACEHOLDER = {
  en: 'Select {n, selectordinal, one {#st}  two {#nd} few {#rd} other {#th}} priority',
  th: 'กรุณาเลือกสิ่งที่ให้ความสำคัญ อันดับที่ {n}',
}

export const CONFIRM_LABEL = {
  en: 'Confirm Your Search',
  th: 'ฉันยืนยันความถูกต้องของข้อมูล',
}

export const INSUFFICIENT_CREDITS_MESSAGE = {
  en: 'Insufficient credits to use this feature, please contact admin@rentaperfect.com',
  th: 'เครดิตของท่านไม่เพียงพอต่อการใช้งานนี้ กรุณาติดต่อ admin@rentaperfect.com',
}
