import moment, { Moment } from 'moment'
import React from 'react'
import * as yup from 'yup'

import Button from '@/common/components/Button'
import DateTimeField from '@/common/components/DateTimeField'
import Form from '@/common/components/Form'
import Gap from '@/common/components/Gap'
import Modal from '@/common/components/Modal'
import NumberField from '@/common/components/NumberField'
import RangeField from '@/common/components/RangeField'
import SelectField from '@/common/components/SelectField'
import useI18n from '@/common/hooks/useI18n'
import useRentalOptions from '@/modules/owner/hooks/useRentalOptions'

import {
  TITLE,
  CONTRACT_START_DATE_TITLE,
  CONTRACT_END_DATE_TITLE,
  PAYMENT_DATE_TITLE,
  PAYMENT_DATE_MIN_TITLE,
  PAYMENT_DATE_MAX_TITLE,
  PRICE_TITLE,
  RENTER_TITLE,
  RENTER_PLACEHOLDER,
  BROKER_TITLE,
  BROKER_PLACEHOLDER,
  SUBMIT,
} from './translation'

const schema = yup.object().shape({
  dateStart: yup.mixed().required(),
  dateExpired: yup.mixed().required(),
  payDate: yup.array().of(yup.number()).required(),
  price: yup.number().required(),
  renterId: yup.string().nullable(),
  brokerId: yup.string().nullable(),
})

interface FormValues {
  dateStart: Moment
  dateExpired: Moment
  payDate: [number, number]
  price: number
  renterId: string
  brokerId?: string
}

interface RentalDetailsFormModalProps {
  room?: RentAPerfect.OwnerRoom
  defaultValues?: Partial<FormValues>
  isOpen: boolean
  onClose: () => void
  onSubmit: (values: FormValues) => void
}

const RentalDetailsFormModal = ({
  room,
  defaultValues,
  isOpen,
  onClose,
  onSubmit,
}: RentalDetailsFormModalProps) => {
  const { t } = useI18n()
  const {
    data: { renters: renterOptions, brokers: brokerOptions },
  } = useRentalOptions({ roomId: room?.id })

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={400} destroyOnClose>
      <Form defaultValues={defaultValues} schema={schema} onSubmit={onSubmit}>
        {({ watch, resetField }) => {
          const dateStart = watch('dateStart')

          return (
            <>
              <div className='text-16 text-semibold text-center mb-24'>
                {t(TITLE)}
              </div>
              <Gap className='mb-24' space={16} vertical>
                <DateTimeField
                  label={CONTRACT_START_DATE_TITLE}
                  name='dateStart'
                  type='date'
                />
                <DateTimeField
                  label={CONTRACT_END_DATE_TITLE}
                  name='dateExpired'
                  type='date'
                  disabledDate={date =>
                    moment(date).startOf('day') <
                    moment(dateStart).startOf('day')
                  }
                />
                <RangeField
                  label={PAYMENT_DATE_TITLE}
                  minLabel={PAYMENT_DATE_MIN_TITLE}
                  maxLabel={PAYMENT_DATE_MAX_TITLE}
                  name='payDate'
                  min={1}
                  max={28}
                  hideRangeBar
                />
                <NumberField label={PRICE_TITLE} name='price' />
                <SelectField
                  label={RENTER_TITLE}
                  placeholder={RENTER_PLACEHOLDER}
                  name='renterId'
                  options={renterOptions}
                  onChange={() => resetField('brokerId')}
                />
                <SelectField
                  label={BROKER_TITLE}
                  placeholder={BROKER_PLACEHOLDER}
                  name='brokerId'
                  options={brokerOptions}
                />
              </Gap>
              <Button type='submit' variant='gradient' fullWidth>
                {t(SUBMIT)}
              </Button>
            </>
          )
        }}
      </Form>
    </Modal>
  )
}

export default RentalDetailsFormModal
