import { RangeProps } from 'rc-slider'
import React from 'react'

import 'rc-slider/assets/index.css'

import { StyledRange } from './styled'

const Range = (props: RangeProps) => {
  return <StyledRange {...props} />
}

export default Range
