export const EDIT = { en: 'Edit profile', th: 'แก้ไขข้อมูลส่วนตัว' }

export const NAME = { en: 'Name', th: 'ชื่อ' }

export const LASTNAME = { en: 'Surname', th: 'นามสกุล' }

export const EMAIL = { en: 'Email', th: 'อีเมล' }

export const PHONE_NUMBER = { en: 'Phone number', th: 'เบอร์โทรศัพท์' }

export const BIRTHDAY = { en: 'Date of birth', th: 'วันเกิด' }

export const SEX = { en: 'Sex', th: 'เพศ' }

export const NATIONALITY = { en: 'Nationality', th: 'สัญชาติ' }

export const LANGUAGE_TO_COMMUNICATE = {
  en: 'Language to communicate',
  th: 'ภาษาที่ใช้สื่อสาร',
}

export const LINE_ID = { en: 'LINE ID', th: 'LINE ID' }

export const CONNECT_TO_LINE = {
  en: 'Connect to LINE',
  th: 'การเชื่อมต่อกับ LINE',
}

export const CONNECTED = { en: 'Connected', th: 'เชื่อมต่อแล้ว' }

export const NOT_CONNECTED = { en: 'Not connected', th: 'ไม่ได้เชื่อมต่อ' }
