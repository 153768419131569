import styled from 'styled-components'

interface FakeInputProps {
  $isChecked?: boolean
  $isDisabled?: boolean
  $isError?: boolean
}

export const Label = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
`

export const Input = styled.input`
  width: 16px;
  height: 16px;
  margin-top: 2px;
  opacity: 0;
  cursor: pointer;
`

export const FakeInput = styled.div<FakeInputProps>`
  color: white;
  background-color: ${({ theme, $isChecked, $isDisabled }) =>
    $isChecked ? theme.colors.success : $isDisabled ? '#eeeeee' : 'white'};
  border: 1px solid
    ${({ theme, $isChecked, $isError }) =>
      $isError
        ? theme.colors.error
        : $isChecked
        ? theme.colors.success
        : '#dddddd'};
  width: 16px;
  height: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  left: 0;
  pointer-events: none;
`
