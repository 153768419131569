import React, { ComponentType } from 'react'

import Breadcrumb, { BreadcrumbProps } from '@/common/components/Breadcrumb'
import Gap from '@/common/components/Gap'
import Link from '@/common/components/Link'
import Separator from '@/common/components/Separator'
import { IconProps } from '@/common/components/icons/types'
import useDevice from '@/common/hooks/useDevice'
import useI18n, { Translation } from '@/common/hooks/useI18n'
import { ME_PATH } from '@/modules/me/paths'
import { MY_PROFILE } from '@/modules/me/translation'

interface MeHeaderProps {
  breadcrumbItems: BreadcrumbProps['items']
  title: Translation
  subTitle?: Translation
  action?: {
    label: Translation
    href?: string
    icon?: ComponentType<IconProps>
    onClick?: () => void
  }
  marginBottom?: number
}

const MeHeader = ({
  breadcrumbItems: _breadcrumbItems,
  title,
  subTitle,
  action,
  marginBottom = 24,
}: MeHeaderProps) => {
  const { t } = useI18n()
  const { isDesktop } = useDevice()

  const breadcrumbItems = [
    !isDesktop && { label: MY_PROFILE, href: ME_PATH },
    ..._breadcrumbItems,
  ].filter(Boolean) as BreadcrumbProps['items']
  const { icon: ActionIcon } = action ?? {}

  return (
    <>
      {breadcrumbItems.length > 1 ? (
        <Breadcrumb className='mb-12' items={breadcrumbItems} />
      ) : null}
      <div className='flex align-center justify-space-between'>
        <Gap className='align-center' space={8}>
          <h1 className='text-20 text-bold'>{t(title)}</h1>
          {subTitle ? (
            <div className='text-gray text-16 text-medium'>{t(subTitle)}</div>
          ) : null}
        </Gap>
        {action ? (
          <Link
            className='text-14 text-semibold text-primary clickable'
            to={action.href}
            onClick={action.onClick}
          >
            <Gap className='align-center' space={4}>
              {ActionIcon ? <ActionIcon size={12} /> : null}
              <div>{t(action.label)}</div>
            </Gap>
          </Link>
        ) : null}
      </div>
      <Separator className={`mt-14 mb-${marginBottom}`} />
    </>
  )
}

export default MeHeader
