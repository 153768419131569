import RCSwitch from 'rc-switch'
import React, { ComponentProps } from 'react'

import 'rc-switch/assets/index.css'

import { StyledSwitch } from './styled'

const Switch = (props: ComponentProps<typeof RCSwitch>) => {
  return <StyledSwitch {...props} />
}

export default Switch
