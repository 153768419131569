import { colord } from 'colord'
import ReactSelect from 'react-select'
import styled from 'styled-components'

interface SelectProps {
  $styles?: { [key: string]: any }
  $isMultiple?: boolean
  $isError?: boolean
}

export const Select = styled(ReactSelect).attrs<SelectProps>(
  ({ theme, $styles, $isMultiple, $isError }) => ({
    styles: {
      ...$styles,
      control: (base, { isFocused }) => ({
        ...base,
        borderColor: `${$isError ? theme.colors.error : '#dddddd'} !important`,
        borderRadius: 4,
        boxShadow: 'none',
        height: $isMultiple ? 'auto' : 36,
        minHeight: $isMultiple ? 38 : 'unset',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        cursor: 'pointer',
        ...(isFocused && {
          boxShadow: '0 0 5px #dddddd',
        }),
        ...$styles?.control,
        '> div:first-child': {
          padding: $isMultiple ? 2 : '0 12px',
          ...$styles?.control?.['> div:first-child'],
          div: {
            margin: `${$isMultiple ? 2 : 0}px !important`,
            ...$styles?.control?.['> div:first-child']?.div,
          },
        },
      }),
      option: (base, { isSelected, isFocused }) => ({
        ...base,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        backgroundColor: isSelected
          ? `${theme.colors.primary} !important`
          : isFocused
          ? `${colord(theme.colors.primary).alpha(0.2).toHex()} !important`
          : 'transparent',
        cursor: 'pointer',
        ...$styles?.option,
      }),
      singleValue: base => ({
        ...base,
        color: 'black',
        ...$styles?.singleValue,
      }),
      placeholder: base => ({
        ...base,
        color: '#818a91',
        padding: $isMultiple ? '0 4px' : 0,
        ...$styles?.placeholder,
      }),
      indicatorSeparator: base => ({
        ...base,
        display: 'none',
        ...$styles?.indicatorSeparator,
      }),
      indicatorsContainer: base => ({
        ...base,
        ...$styles?.indicatorsContainer,
        div: {
          padding: '0 8px 0 0',
          ...$styles?.indicatorsContainer?.div,
        },
        svg: {
          color: 'black',
          width: 18,
          height: 18,
          ...$styles?.indicatorsContainer?.svg,
        },
      }),
      multiValue: base => ({
        ...base,
        backgroundColor: '#f4f4f4',
        borderRadius: 4,
        ...$styles?.multiValue,
        '> div:first-child': {
          fontSize: 12,
          fontWeight: 600,
          paddingRight: 0,
          ...$styles?.multiValue?.['> div:first-child'],
        },
        '> div[role="button"]': {
          color: 'black',
          backgroundColor: 'transparent',
          paddingLeft: 0,
          ...$styles?.multiValue?.['> div[role="button"]'],
        },
      }),
    },
  })
)<SelectProps>``
