import React, { InputHTMLAttributes } from 'react'
import { Controller } from 'react-hook-form'

import FieldWrapper, {
  FieldWrapperProps,
} from '@/common/components/FieldWrapper'
import { useForm } from '@/common/components/Form'
import useI18n, { Translation } from '@/common/hooks/useI18n'

import { Select } from './styled'

export type SelectOption<T> = {
  value: T
  label: Translation
}

export type SelectFieldProps<T> = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'placeholder' | 'value' | 'onChange'
> &
  FieldWrapperProps & {
    styles?: { [key: string]: any }
    placeholder?: Translation
    options: SelectOption<T>[]
    valueOption?: SelectOption<T> | SelectOption<T>[]
    multiple?: boolean
    isLoading?: boolean
    disabledFiltering?: boolean
    value?: T | T[]
    onChange?: (
      value: T | T[],
      option: SelectOption<T> | SelectOption<T>[]
    ) => void
    onInputChange?: (value: string) => void
  }

const SelectField = <T extends unknown>({
  className,
  styles,
  name,
  placeholder = '',
  value: _value,
  valueOption: _valueOption,
  options: _options,
  multiple,
  isLoading,
  disabledFiltering,
  onChange,
  onInputChange,
  ...props
}: SelectFieldProps<T>) => {
  const { t } = useI18n()
  const { control } = useForm()

  const options = _options.map(({ value, label }) => ({
    value,
    label: t(label),
  }))
  const valueOption = _valueOption
    ? multiple
      ? (_valueOption as SelectOption<T>[]).map(({ value, label }) => ({
          value,
          label: t(label),
        }))
      : { ..._valueOption, label: t((_valueOption as SelectOption<T>).label) }
    : undefined

  const selectProps = {
    ...props,
    $styles: styles,
    $isMultiple: multiple,
    instanceId: name,
    options,
    isLoading,
    isMulti: multiple,
    isClearable: false,
    placeholder: t(placeholder),
    filterOption: disabledFiltering ? () => true : undefined,
    onInputChange: (value = '') => onInputChange?.(value),
  }

  const getSelectedOption = (value: T | T[]) => {
    return (
      valueOption ??
      (multiple
        ? ((value as T[]) ?? []).map(v => options.find(o => o.value === v))
        : options.find(o => o.value === (value as T)) ?? null)
    )
  }

  const getOptionValue = (option: SelectOption<T> | SelectOption<T>[]) => {
    return multiple
      ? (option as SelectOption<T>[]).map(o => o.value)
      : (option as SelectOption<T>).value
  }

  const renderValue = (value: T | T[]) =>
    [getSelectedOption(value)]
      .flat()
      .filter(Boolean)
      .map(o => t(o?.label ?? ''))
      .join(', ')

  if (!name || !control)
    return (
      <FieldWrapper
        {...props}
        className={className}
        name={name}
        value={_value}
        renderValue={renderValue}
      >
        {({ isError }) => (
          <Select
            {...selectProps}
            $isError={isError}
            value={getSelectedOption(_value)}
            onChange={(option: SelectOption<T> | SelectOption<T>[]) =>
              onChange?.(getOptionValue(option), option)
            }
          />
        )}
      </FieldWrapper>
    )

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FieldWrapper
          {...props}
          className={className}
          name={name}
          value={_value}
          renderValue={renderValue}
        >
          {({ isError }) => (
            <Select
              {...field}
              {...selectProps}
              $isError={isError}
              value={getSelectedOption(field.value)}
              onChange={(option: SelectOption<T> | SelectOption<T>[]) => {
                const newValue = getOptionValue(option)
                field.onChange(newValue)
                onChange?.(newValue, option)
              }}
            />
          )}
        </FieldWrapper>
      )}
    />
  )
}

export default SelectField
