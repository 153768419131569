import React, { InputHTMLAttributes } from 'react'

import FieldWrapper, {
  FieldWrapperProps,
} from '@/common/components/FieldWrapper'
import { useForm } from '@/common/components/Form'
import CheckIcon from '@/common/components/icons/CheckIcon'
import useI18n from '@/common/hooks/useI18n'

import { Label, Input, FakeInput } from './styled'

type CheckBoxFieldProps = InputHTMLAttributes<HTMLInputElement> &
  FieldWrapperProps

const CheckBoxField = ({
  className,
  name,
  label,
  checked = false,
  ...props
}: CheckBoxFieldProps) => {
  const { t } = useI18n()
  const { register, watch } = useForm()

  const isChecked = (name ? watch?.(name) : undefined) || checked
  const isDisabled = props.disabled ?? false

  return (
    <FieldWrapper
      {...props}
      className={className}
      name={name}
      showError={false}
    >
      {({ isError }) => (
        <Label>
          <Input
            {...props}
            {...(name && register?.(name))}
            name={name}
            type='checkbox'
          />
          <FakeInput
            $isChecked={isChecked}
            $isDisabled={isDisabled}
            $isError={isError}
          >
            {isChecked ? <CheckIcon size={10} /> : null}
          </FakeInput>
          {label && (
            <div className='flex-1 text-14 text-medium ml-8'>{t(label)}</div>
          )}
        </Label>
      )}
    </FieldWrapper>
  )
}

export default CheckBoxField
