import { useQuery } from 'react-query'

import useApis from '@/common/hooks/useApis'
import usePermission from '@/common/hooks/usePermission'

const QUERY_KEY = 'rental-options'

interface Props {
  roomId?: string
}

const useRentalOptions = ({ roomId }: Props) => {
  const { apis } = useApis()
  const { isOwner } = usePermission()

  const { data = { renters: [], brokers: [] }, ...query } = useQuery(
    [QUERY_KEY, { roomId }],
    () => apis.owner.getRentalOptions({ roomId: roomId! }),
    { enabled: isOwner && !!roomId }
  )

  return { ...query, data }
}

export default useRentalOptions
