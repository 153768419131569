import { colord } from 'colord'
import { Moment } from 'moment'
import Picker, { PickerProps } from 'rc-picker'
import React from 'react'
import styled, { css } from 'styled-components'

const Component = ({ className, ...rest }: PickerProps<Moment>) => (
  <Input dropdownClassName={className} {...rest} />
)

const applyStyles = type => {
  switch (type) {
    case 'date':
      return css`
        .rc-picker-panel {
          padding: 8px;
        }

        .rc-picker-header {
          padding: 4px 0 8px;

          button {
            border: none;
            background: transparent;
            cursor: pointer;
          }
        }

        .rc-picker-cell > div {
          border-radius: 4px;
          margin: 2px 4px;

          &:hover {
            background-color: ${({ theme }) =>
              colord(theme.colors.primary).alpha(0.2).toHex()};
          }
        }

        .rc-picker-cell-selected > div {
          color: white !important;
          background-color: ${({ theme }) => theme.colors.primary};

          &:hover {
            background-color: ${({ theme }) => theme.colors.primary};
          }
        }

        .rc-picker-cell-today > div {
          border-color: ${({ theme }) => theme.colors.primary};
        }
      `
    case 'time':
      return css`
        .rc-picker-time-panel-cell {
          &:hover {
            background-color: ${({ theme }) =>
              colord(theme.colors.primary).alpha(0.2).toHex()};
          }
        }

        .rc-picker-time-panel-cell-selected > div {
          color: white !important;
          font-weight: 500;
          background-color: ${({ theme }) => theme.colors.primary};
        }

        .rc-picker-time-panel-cell-inner {
          text-align: center !important;
          height: auto !important;
          padding: 4px !important;
        }

        .rc-picker-time-panel-column {
          width: 100px;
          padding: 0;

          &:not(:last-child) {
            border-right: 1px solid #dddddd;
          }
        }

        .rc-picker-footer {
          display: none;
        }
      `
    default:
      return css``
  }
}

export const Input = styled(Picker)`
  width: 100%;
  border: none !important;

  input {
    padding-right: 38px;
    cursor: pointer;

    &::placeholder {
      color: #818a91 !important;
    }
  }

  .rc-picker-suffix {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`

export const Dropdown = styled(Component)`
  ${({ picker }) => applyStyles(picker)};
  box-shadow: none !important;

  .rc-picker-panel {
    background-color: white;
    border-radius: 4px;
    border: 1px solid #dddddd;
  }
`

export const StyledPicker = styled(Dropdown)``
