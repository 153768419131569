import loadable from '@loadable/component'
import moment from 'moment'
import React, { Fragment } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useOutletContext } from 'react-router-dom'
import * as yup from 'yup'

import Button from '@/common/components/Button'
import DateTimeField from '@/common/components/DateTimeField'
import FieldWrapper from '@/common/components/FieldWrapper'
import Form from '@/common/components/Form'
import Gap from '@/common/components/Gap'
import SelectField from '@/common/components/SelectField'
import Switch from '@/common/components/Switch'
import TextField from '@/common/components/TextField'
import { SEX_OPTIONS, LANGUAGE_OPTIONS } from '@/common/enums'
import useApis from '@/common/hooks/useApis'
import useI18n from '@/common/hooks/useI18n'
import useLoadingOverlay from '@/common/hooks/useLoadingOverlay'
import useSwitch from '@/common/hooks/useSwitch'
import { SAVE, SAVE_SUCCESS_MESSAGE } from '@/common/translation'
import useCurrentUser from '@/modules/authentication/hooks/useCurrentUser'
import MeHeader from '@/modules/me/components/MeHeader'
import {
  ME_PERSONAL_INFORMATION_PATH,
  ME_PERSONAL_INFORMATION_EDIT_PATH,
} from '@/modules/me/paths'
import { PERSONAL_INFORMATION } from '@/modules/me/translation'

import { FormItemsWrapper } from './styled'
import {
  EDIT,
  NAME,
  LASTNAME,
  EMAIL,
  PHONE_NUMBER,
  BIRTHDAY,
  SEX,
  NATIONALITY,
  LANGUAGE_TO_COMMUNICATE,
  LINE_ID,
  CONNECT_TO_LINE,
  CONNECTED,
  NOT_CONNECTED,
} from './translation'

const LineConnectModal = loadable(
  () => import('./components/LineConnectModal'),
  { ssr: false }
)

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().nullable(),
  phone: yup.string().nullable(),
  birthDate: yup.mixed().nullable(),
  sex: yup.string().nullable(),
  nationality: yup.string().nullable(),
  language: yup.string().nullable(),
  lineId: yup.string().nullable(),
})

interface PersonalInformationPageProps {
  editable?: boolean
}

const PersonalInformationPage = ({
  editable,
}: PersonalInformationPageProps) => {
  const { t } = useI18n()
  const { apis } = useApis()
  const navigate = useNavigate()
  const loadingOverlay = useLoadingOverlay()
  const { data: currentUser, refetch: refetchCurrentUser } = useCurrentUser()
  const { editingProfileImage } =
    useOutletContext<{ editingProfileImage: File | null }>()
  const {
    value: isOpenLineConnectModal,
    on: openLineConnectModal,
    off: closeLineConnectModal,
  } = useSwitch()

  const breadcrumbItems = [{ label: PERSONAL_INFORMATION }]

  const onToggleLineConnect = isConnected => {
    if (isConnected) openLineConnectModal()
    else onUpdateLineConnect()
  }

  const onUpdateLineConnect = async () => {
    loadingOverlay.open()

    await apis.user
      .updateLineConnect()
      .then(async () => {
        await refetchCurrentUser()
      })
      .finally(() => {
        loadingOverlay.close()
      })
  }

  const onSubmit = async values => {
    loadingOverlay.open()

    await apis.user
      .updateProfile({
        ...values,
        profileImage: editingProfileImage,
        birthDate: values.birthDate
          ? moment(values.birthDate).format('YYYY-MM-DD')
          : undefined,
      })
      .then(async () => {
        await refetchCurrentUser()
        toast.success(t(SAVE_SUCCESS_MESSAGE))
        navigate(ME_PERSONAL_INFORMATION_PATH)
      })
      .finally(() => {
        loadingOverlay.close()
      })
  }

  return (
    <Fragment key={`editable-${editable}`}>
      <MeHeader
        breadcrumbItems={breadcrumbItems}
        title={PERSONAL_INFORMATION}
        action={
          !editable
            ? { label: EDIT, href: ME_PERSONAL_INFORMATION_EDIT_PATH }
            : undefined
        }
      />
      <Form
        defaultValues={{
          firstName: currentUser!.firstName,
          lastName: currentUser!.lastName,
          email: currentUser!.email,
          phone: currentUser!.phone,
          birthDate: currentUser!.birthDate
            ? moment(currentUser!.birthDate)
            : undefined,
          sex: currentUser!.sex,
          nationality: currentUser!.nationality,
          language: currentUser!.language,
          lineId: currentUser!.lineId,
        }}
        schema={schema}
        info={!editable}
        onSubmit={onSubmit}
      >
        {({ formState: { isSubmitting } }) => (
          <>
            <FormItemsWrapper>
              <TextField name='firstName' label={NAME} placeholder={NAME} />
              <TextField
                name='lastName'
                label={LASTNAME}
                placeholder={LASTNAME}
              />
              <TextField name='email' label={EMAIL} placeholder={EMAIL} />
              <TextField
                name='phone'
                label={PHONE_NUMBER}
                placeholder={PHONE_NUMBER}
              />
              <DateTimeField
                type='date'
                name='birthDate'
                label={BIRTHDAY}
                placeholder={BIRTHDAY}
              />
              <SelectField
                name='sex'
                label={SEX}
                placeholder={SEX}
                options={SEX_OPTIONS}
              />
              <TextField
                name='nationality'
                label={NATIONALITY}
                placeholder={NATIONALITY}
              />
              <SelectField
                name='language'
                label={LANGUAGE_TO_COMMUNICATE}
                placeholder={LANGUAGE_TO_COMMUNICATE}
                options={LANGUAGE_OPTIONS}
              />
              <TextField name='lineId' label={LINE_ID} placeholder={LINE_ID} />
              <FieldWrapper
                label={CONNECT_TO_LINE}
                value={currentUser!.lineConnect ?? false}
                renderValue={value => t(value ? CONNECTED : NOT_CONNECTED)}
              >
                <Gap className='align-center pt-8 pt-0-mobile' space={8}>
                  <Switch
                    checked={currentUser!.lineConnect ?? false}
                    onChange={onToggleLineConnect}
                  />
                  <div className='text-14 text-medium'>{t(CONNECTED)}</div>
                </Gap>
              </FieldWrapper>
            </FormItemsWrapper>
            {editable ? (
              <Button
                className='mt-32 ml-auto px-40'
                type='submit'
                variant='gradient'
                disabled={isSubmitting}
              >
                {t(SAVE)}
              </Button>
            ) : null}
          </>
        )}
      </Form>
      <LineConnectModal
        isOpen={isOpenLineConnectModal}
        onClose={closeLineConnectModal}
        onSubmit={() => {
          onUpdateLineConnect().then(() => {
            closeLineConnectModal()
          })
        }}
      />
    </Fragment>
  )
}

export default PersonalInformationPage
