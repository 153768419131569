import styled from 'styled-components'

import Button from '@/common/components/Button'
import { media } from '@/common/utils/viewport'

export const SubmitButton = styled(Button).attrs({
  variant: 'gradient',
  fullWidth: true,
})`
  ${media.up('sm')`
    max-width: 393px;
  `}
`
