import cn from 'classnames'
import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  $isError?: boolean
  $isMultiline?: boolean
}

export const InputWrapper = styled.div`
  position: relative;
`

export const Input = styled.input<InputProps>`
  background-color: white;
  border: ${({ theme, $isError }) =>
    `1px solid ${$isError ? theme.colors.error : '#dddddd'}`};
  border-radius: 4px;
  width: 100%;
  height: ${({ $isMultiline }) => ($isMultiline ? 'auto' : '36px')};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  padding: ${({ $isMultiline }) => ($isMultiline ? 10 : 0)}px 12px;
  resize: vertical;

  &::placeholder {
    color: #818a91;
  }

  &:focus {
    box-shadow: 0 0 5px #dddddd;
  }

  &:disabled {
    background-color: #eeeeee;
  }
`

const IconWrapper = styled.div.attrs(({ onClick }) => ({
  className: cn('flex align-center', onClick && 'clickable'),
}))`
  color: #c4c4c4;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const LeftIconWrapper = styled(IconWrapper)`
  left: 12px;
`

export const RightIconWrapper = styled(IconWrapper)`
  right: 12px;
`
